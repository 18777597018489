import React from 'react';
import ButtonChoice from './ButtonChoice';
//import ChatComponent from './ChatComponent';
import { PromptProvider } from './PromptContext';
import PersonalizedBar from './PersonalizedBar';
import './SecondPage.css';
import ProgressBar from './ProgressBar';
import ChatComponent from './ChatComponent';
//import QuestionDisplay from './QuestionDisplay';


//Removed from header which is now smaller: <h3 color="ffffff">Use our AI tools to enhance your SAT preparation.</h3>

const SecondPage = () => {
    
    return (
        <main>
            <section className = "hero-second-page">
                    <div className="hero-content">
                        
                        <h2>Ready to get started with SAT Practice?</h2>
                        <a href="#get-started" className="cta-button">Get Started</a>
                    </div>
            </section>

            <section className = "main-area">
                
                <PromptProvider>
                    <PersonalizedBar />
                    <div className="sat-area">
                        <div className="skills-and-questions-left-section">
                            <ButtonChoice />
                        </div>
                        <div className="chat-component-right-section">
                            <ChatComponent />
                        </div>
                    </div>
                    <ProgressBar />
                    
                </PromptProvider>
            </section>   
    </main>
  );
};
// <ChatComponent />
// used to be underneath <ButtonCHoice /> above
//But this was removed on 3:23 pm on 11/6 when ChatComponent was added within SkillButtonsAndQuestionGenerator

export default SecondPage;

//                    <p>After answering, copy the question above into the box below, then type "My answer is" + your answer. Then say, "did I get it right, and can you walk me through how you would solve it?</p>
