import React, {FC, useState, useEffect, useContext, useRef} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './index.css';
import './AppStyles.css';
//import ChatComponent from './components/ChatComponent';
import HomePage from './components/HomePage';
import SecondPage from './components/SecondPage';
import Login from './components/Login';
import { UserProvider } from './components/UserContext'; //shares user data from Firestore throughout Components.


const App: FC = () => {
  const [showLogin, setShowLogin] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null); // Create a ref for the dropdown
  
  const toggleDropdown = () => {
    setShowLogin(!showLogin);
  };

  const handleLoginSuccess = () => {
    setShowLogin(false); // Close the dropdown on successful login
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowLogin(false); //close dropdown if clicked outside (using these event handlers below to detect the click)
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  //UserProvider wraps everything, making the user context available everywhere.
  return (
    <UserProvider>
      <Router>
        <div className="App">
        <header className="App-header">
          
          <h1>Next Step Education SAT Prep AI</h1>
          <div className="nav-bar">
            <nav>
              <a href="/" className="nav-link">Home</a>
              <a href="/sat" className="nav-link">SAT</a>
              <a href="https://www.next-step-education.com/home-2" className="nav-link">AI Training</a>
            </nav>
          </div>


          {/* Render the Login component here */}
          <Login onLoginSuccess={handleLoginSuccess} toggleDropdown={toggleDropdown} dropdownRef={dropdownRef} showLogin={showLogin} />

        </header>

        <Routes> 
          <Route path ="/" element={<HomePage />} />
          <Route path ="/sat" element={<SecondPage />} />
        </Routes>

        <footer>
          <p>© 2024 Next Step Education AI Tutoring. All rights reserved.</p>
        </footer>
      </div>
      </Router>
    </UserProvider>
  );
}

export default App;

//<img src='/images/logo-N.png'  alt="Next Step Education Logo" className="App-logo"/> //logo that was previously in teh top left corner

//put this after dropDownRef:
//const {user, loading, initialLoading} = useContext(UserContext); //loads logged in user's info here.

  /*only runs when user or loading change
  useEffect(() => {
    console.log('User data in App.tsx:', user); //NEW on 11/15 at 7:35 am: Log user data for debugging purposes
    console.log(`User's first name is ${user?.firstName}`);
    console.log('Loading state:', loading);
  }, [user, loading]);
  */
  
  /* Function to truncate the name if longer than 12 characters
  const getDisplayName = (name: string) => {
    return name.length > 12 ? `${name.slice(0, 9)}...` : name;
  }; */
  
  // Toggle dropdown visibility




//Put this after <Login>
/* Display user's first name if logged in, else show "Login" 3:19 am 11/13 - removed "!loading && " before "user" maybe add back in if it's messed up!}
          {initialLoading ? (
              <button className="login-button">Loading...</button>
            ) : user ? (
              <button className="login-button" onClick={toggleDropdown}>
                {getDisplayName(user.firstName)}
              </button>
            ) : (
              <button className="login-button" onClick={toggleDropdown}>Login</button>
            )}


          {/* Conditionally render the dropdown          
          <div ref={dropdownRef} className={`login-dropdown ${showLogin ? 'show' : ''}`}>
              <Login onLoginSuccess={handleLoginSuccess}/>
            </div> */






//Removed Registration page: <a href="/register" className="nav-link">Sign Up</a>

//previous Login, next to all the other nav hrefs: <a href="/login" className="nav-link">Login</a>
//Router to now nonexistent Login page: <Route path="/login" element={<Login />} />



//import reportWebVitals from './reportWebVitals.js';
//import BackendTestComponent from './components/BackendTestComponent.js'; //<BackendTestComponent />
// //put this  nearby ChatComponent if you want, although it's not working now on 6/24 at 4:53 pm

