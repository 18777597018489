// SkillButtonsAndQuestionGenerator.tsx
import React, { useState, useContext, useCallback, FormEvent } from 'react';
import { UserContext } from './UserContext';
import { PromptContext } from './PromptContext';
//import ChatComponent from './ChatComponent'

type SkillButtonsAndQuestionGeneratorProps = {
  selectedSubject: string; // 'Math' or 'Reading and Writing'
};

const skillsByType: Record<string, string[]> = {
  "Reading and Writing": ['Descriptive Phrases', 'Function of Sentence', 'Inferences', 'Main Idea', 'Phrasing', 'Pronouns', 'Punctuation', 'Referencing Data', 'Supporting Claims', "Synthesizing Notes", 'Tenses', 'Text Structure', 'Transition Words', 'Two Passages', 'Word Choice'],
  "Math": ['Absolute Value', 'Algebra', 'Circles', 'Exponential Equations', 'Exponential Word Problems', 'Geometry', 'Interpreting Graphs', 'Linear Equations', 'Linear Word Problems', 'Inequality Word Problems', 'Percent', 'Polynomial Expressions', 'Probability', 'Quadratic Equations', 'Quadratic Word Problems', 'Statistics', 'Systems of Equations', 'Trigonometry', 'Unit Conversions'],
};

// Function to convert display names to skill keys
const skillNameToKey = (skillName: string): string => {
  return skillName.toLowerCase().replace(/ /g, '_');
};

const SkillButtonsAndQuestionGenerator: React.FC<SkillButtonsAndQuestionGeneratorProps> = ({ selectedSubject }) => {
  const [isGenerating, setIsGenerating] = useState<boolean>(false); //toggles display button when loading a question to screen
  const [generatingSkill, setGeneratingSkill] = useState<string | null>(null); //tracks which skill's question is generated to change button
  const { user, updateUserData } = useContext(UserContext);
  const {questionData, questionText, choices, correctAnswer, explanationText, setQuestionText, setChoices, setCorrectAnswer, setExplanationText, 
    selectedSkill, setSelectedSkill, selectedDifficulty, setSelectedDifficulty, clearChat, renderLatexOrText, 
    interactionStage, setInteractionStage} = useContext(PromptContext)!;
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');
  const [freeResponseInput, setFreeResponseInput] = useState<string>(''); // New: State for free-response input
  const [feedback, setFeedback] = useState<string>('');
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState<boolean>(false);
  const [incorrectAnswers, setIncorrectAnswers] = useState<string[]>([]); //incorrect answers that have been subitted, so the user can try again
  const [hasSubmittedFirstAttempt, setHasSubmittedFirstAttempt] = useState<boolean>(false);

  const generateQuestion = useCallback(async (skill: string, difficulty: 'Easy' | 'Medium' | 'Hard') => {
    if (skill === "") {
      console.error('No skill selected. Please try again.');
      return;
    }
    
    //This will make skill accessible throughout components.
    setSelectedSkill(skill);
    setGeneratingSkill(skill); // Set the current generating skill to make its skill button different color
    setSelectedDifficulty(difficulty);
    setIsGenerating(true);
    setFeedback(''); //clears the feedback from the previous question
    clearChat(); //clears Chat history (in PromptContext) when new question is generator to not clog up ChatComponent
    setSelectedAnswer(''); //clears previous answer choice (A, etc.) so it's not highlighted if the same skill question is generated
    setCorrectAnswer(''); //clear previous correct answer (D, etc.) so it's not highlighted if the same skill question is generated
    setIsAnswerSubmitted(false);
    setIncorrectAnswers([]) //reset incorrect answers from the previous question
    setHasSubmittedFirstAttempt(false);

    //**Note on 10/8: Still need to figure out the free response Questions!
    try {
      if (!questionData) {
        throw new Error('Questions are not yet loaded. Please wait.');
      }
      const filteredSkillQuestions = questionData.filter((q: any) => q.skill === skill); 
      const filteredDifficultyQuestions = filteredSkillQuestions.filter((q: any) => q.difficulty === difficulty); 
      //console.log(`${filteredDifficultyQuestions.length} ${difficulty} questions for ${skill}.`);

      if (filteredDifficultyQuestions.length === 0) {
        throw new Error(`No questions found for ${skill} at ${difficulty} difficulty.`);
      }

      // Retrieve current question index based on difficulty
      const skillKey = skillNameToKey(skill);
      const currentQuestionIndex = 
          difficulty === 'Easy'? user?.skills[skillKey]?.easyQuestionsIndex || 0
          : difficulty === 'Medium' ? user?.skills[skillKey]?.mediumQuestionsIndex || 0
          : user?.skills[skillKey]?.hardQuestionsIndex || 0;

      const selectedQuestion = filteredDifficultyQuestions[currentQuestionIndex]

      // Set the state variables with the selected question data
      setQuestionText(selectedQuestion.question);
      setChoices(selectedQuestion.choices || '');
      setCorrectAnswer(selectedQuestion.answer);
      setExplanationText(selectedQuestion.explanation || '');
      // Set interaction stage after successfully generating a question for use when rendering quickPrompts - pre-question, during, or after submitting answer?
      setInteractionStage('questionGenerated');

      //*********RESUME HERE TOMORROW. I CAN'T THINK AT 4:00 AM! */
      // Update user's total questions attempted. If they have no data, default to 0 for everything.
      if (user && selectedSkill) {
        const skillData = user.skills[skillKey] || {easyQuestionsIndex: 0, mediumQuestionsIndex: 0, hardQuestionsIndex: 0,};

        // Increment the index and loop back if it reaches the end of the array
        const newIndex = (currentQuestionIndex + 1) % filteredDifficultyQuestions.length;
        const difficultyIndexKey = `${difficulty.toLowerCase()}QuestionsIndex` as 'easyQuestionsIndex' | 'mediumQuestionsIndex' | 'hardQuestionsIndex'; //= "easyQuestionsIndex", "mediumQuestionsIndex", or "hardQuestionsIndex"
        
        const updatedSkillData = {
          ...user.skills,
          [skillKey]: {...skillData, 
            [difficultyIndexKey]: newIndex 
          },
        };

        updateUserData({skills: updatedSkillData});
      }
    } catch (error) {
      console.error('Error generating question:', error);
    } finally {
      setIsGenerating(false);
      setGeneratingSkill(null); //resets color difference for skill button
    }
  }, [selectedSubject, selectedSkill, setSelectedSkill, clearChat, updateUserData, user, questionData,]); //Should I remove the comma after user?


  // Toggle skill selection based on which skill box the user clicks
  const handleSkillClick = (skill: string) => {
    setSelectedSkill(skill === selectedSkill ? "" : skill); 
    // Clear previous question and feedback when changing skill. Added at 11:00 am on 9/19
    setQuestionText('');
    setChoices([]);
    setCorrectAnswer('');
    setExplanationText('');
    setSelectedAnswer('');
    setFeedback('');
    setInteractionStage('notStarted');
  };  

  // Function to calculate overall skill percentage correct from user data
  const calculateSpecificDifficultyPercentage = (skill: string, difficulty: 'Easy' | 'Medium' | 'Hard') => {
    //console.log(`In calculateSpecificDifficultyPercentage for ${difficulty} ${skill}`);
    if (!user) return 0;
    const skillKey = skillNameToKey(skill);
    const skillData = user.skills[skillKey];
    if (difficulty === 'Easy'){
      const attempted = skillData?.currentTestEasyQuestionsAttempted || 0;
      const correct = skillData?.currentTestEasyQuestionsCorrect || 0;
      //console.log(`easyQAttempted / Correct is ${attempted} / ${correct} = ${Math.round(attempted / correct) * 100}`);
      return attempted === 0 ? 0 : Math.round((correct / attempted) * 100);
    } else if (difficulty === 'Medium'){
      const attempted = skillData?.currentTestMediumQuestionsAttempted || 0;
      const correct = skillData?.currentTestMediumQuestionsCorrect || 0;
      //console.log(`mediumQAttempted / Correct is ${attempted} / ${correct} = ${Math.round(attempted / correct) * 100}`);
      return attempted === 0 ? 0 : Math.round((correct / attempted) * 100);
    } else{
      const attempted = skillData?.currentTestHardQuestionsAttempted || 0;
      const correct = skillData?.currentTestHardQuestionsCorrect || 0;
      //console.log(`hardQAttempted / Correct is ${attempted} / ${correct} = ${Math.round(attempted / correct) * 100}`);
      return attempted === 0 ? 0 : Math.round((correct / attempted) * 100);
    }
  };

  //*****UPDATE ME IN THE FUTURE! This is where setFeedback can be helpful when making AN INCORRECT GUESS
  const handleSubmitAnswer = () => {
    console.log(`in handleSubmitAnswer, difficulty is ${selectedDifficulty}`);
    if (selectedAnswer === '') {
      setFeedback('Please select an answer.');
      return;
    }
    const isCorrect = selectedAnswer === correctAnswer;
    if (isCorrect === true){
      setFeedback('Correct!'); //Will I even use setFeedback since the buttons become green/red for right/wrong?
    }
    setIsAnswerSubmitted(true);
    setInteractionStage('answered');

    if (!isCorrect){ //if incorrect answer, add it to an array to keep it highlighted red
      setIncorrectAnswers((prev) => [...prev, selectedAnswer])
    } //****Might have an async problem where I need to do "setTimeout" if this doesn't work: */

    // Update user's correct answer count
    if (!hasSubmittedFirstAttempt && user && selectedSkill) {
      setHasSubmittedFirstAttempt(true); // New: Mark as the first attempt to prevent further updates

      const skillKey = skillNameToKey(selectedSkill);
      const skillData = user.skills[skillKey] || {
        overallQuestionsAttempted: 0, overallQuestionsCorrect: 0,
        currentTestEasyQuestionsAttempted: 0, currentTestMediumQuestionsAttempted: 0, currentTestHardQuestionsAttempted: 0,
        currentTestEasyQuestionsCorrect: 0, currentTestMediumQuestionsCorrect: 0, currentTestHardQuestionsCorrect: 0,
      };

      // Increment the relevant attempted and correct keys based on difficulty
      if (selectedDifficulty === 'Easy') {
        skillData.currentTestEasyQuestionsAttempted += 1;
        if (isCorrect) {
          skillData.currentTestEasyQuestionsCorrect += 1;
          skillData.overallQuestionsCorrect += 1;
          console.log(`Increased easy Questions correct ${skillData.currentTestEasyQuestionsAttempted} by 1`);
        }
      } else if (selectedDifficulty === 'Medium') {
        skillData.currentTestMediumQuestionsAttempted += 1;
        if (isCorrect) {
          skillData.currentTestMediumQuestionsCorrect += 1;
          skillData.overallQuestionsCorrect += 1;
          console.log(`Increased medium Questions correct ${skillData.currentTestMediumQuestionsAttempted} by 1`);
        }
      } else if (selectedDifficulty === 'Hard') {
        skillData.currentTestHardQuestionsAttempted += 1;
        if (isCorrect) {
          skillData.currentTestHardQuestionsCorrect += 1;
          skillData.overallQuestionsCorrect += 1;
          console.log(`Increased hard Questions correct ${skillData.currentTestHardQuestionsAttempted} by 1`);
        }
      }
      // Increment overall attempted count
      skillData.overallQuestionsAttempted += 1;

      const updatedSkillData = {
        ...user.skills,
        [skillKey]: skillData,
      };
        updateUserData({ skills: updatedSkillData, grandTotalQuestions: (user.grandTotalQuestions || 0) + 1 });      
    }
  };  

  // Function to handle the free-response form submission for Math questions
  const handleFreeResponseSubmit = (e: FormEvent) => {
    e.preventDefault(); // Prevent default form submission behavior
    if (freeResponseInput.trim() === '') {
      setFeedback('Please enter your answer.');
      return;
    }

    const isCorrect = freeResponseInput.trim() === correctAnswer;
    setFeedback(isCorrect ? 'Correct!' : 'Incorrect, please try again!');
    setIsAnswerSubmitted(true);
    setInteractionStage('answered');

    if (!hasSubmittedFirstAttempt && user && selectedSkill) {
      setHasSubmittedFirstAttempt(true); // Mark as the first attempt to prevent further updates

      const skillKey = skillNameToKey(selectedSkill);
      const skillData = user.skills[skillKey] || {overallQuestionsAttempted: 0, overallQuestionsCorrect: 0,
          currentTestEasyQuestionsAttempted: 0, currentTestMediumQuestionsAttempted: 0, currentTestHardQuestionsAttempted: 0,
          currentTestEasyQuestionsCorrect: 0, currentTestMediumQuestionsCorrect: 0, currentTestHardQuestionsCorrect: 0,
      };

      // Increment the relevant attempted and correct keys based on difficulty
      if (selectedDifficulty === 'Easy') {
          skillData.currentTestEasyQuestionsAttempted += 1;
          if (isCorrect) {
              skillData.currentTestEasyQuestionsCorrect += 1;
              skillData.overallQuestionsCorrect += 1;
          }
      } else if (selectedDifficulty === 'Medium') {
          skillData.currentTestMediumQuestionsAttempted += 1;
          if (isCorrect) {
              skillData.currentTestMediumQuestionsCorrect += 1;
              skillData.overallQuestionsCorrect += 1;
          }
      } else if (selectedDifficulty === 'Hard') {
          skillData.currentTestHardQuestionsAttempted += 1;
          if (isCorrect) {
              skillData.currentTestHardQuestionsCorrect += 1;
              skillData.overallQuestionsCorrect += 1;
          }
      }
      skillData.overallQuestionsAttempted += 1; // Increment overall attempted count

      // Update user data with the modified skill data
      const updatedSkillData = {...user.skills, [skillKey]: skillData,};
      updateUserData({ skills: updatedSkillData, grandTotalQuestions: (user.grandTotalQuestions || 0) + 1 });
    }

    setFreeResponseInput(''); // Clear the input field after submission
  };

  return (
    <div>
      {/* Parent grid container for skill progress boxes */}
      <div className="skill-grid">
        {skillsByType[selectedSubject].map((skill) => {
          const skillKey = skillNameToKey(skill);
          const isSelected = selectedSkill === skill;
          const attempted = user?.skills[skillKey]?.overallQuestionsAttempted || 0;

          return (
            <div
              key={skill}
              className={`skill-progress-box ${isSelected ? 'selected' : ''}`}
              onClick={() => handleSkillClick(skill)}
            >
              {/* Skill Name and Completion Info */}
              <h4 className="skill-title">{skill}</h4>

            </div>
          );
        })}
      </div>

      {/* Display the question if it's generated. The "questionText &&" is the conditional rendering; remove during development to see 
      live changes: {questionText && (*/}
      {(
        <div className="question-display">            
          {/* Skill title and overall progress */}
          {(() => {
            const skillKey = skillNameToKey(selectedSkill);
            const skillData = user?.skills[skillKey] || { overallQuestionsAttempted: 0, overallQuestionsCorrect: 0 };
            const attempted = skillData.overallQuestionsAttempted || 0;
            const correct = skillData.overallQuestionsCorrect || 0;
            const percentCorrect = attempted === 0 ? 0 : Math.round((correct / attempted) * 100);

            return (
              <div className="skill-progress-summary">
                <h2 className="centered-skill-title">{selectedSkill}</h2>
                <p>{attempted} total questions attempted</p>
                <p>{percentCorrect}% correct</p>
              </div>
            );
          })()}

          {/* New Generate Questions with difficulties in vertical columns */}
          <div className="generate-question-buttons-by-difficulty-grid">
            {['Easy', 'Medium', 'Hard'].map((difficulty) => (
              <div key={difficulty} className="generate-question-difficulty-row">
                  <button onClick={() => generateQuestion(selectedSkill, difficulty as 'Easy' | 'Medium' | 'Hard')}
                    className="cta-button" disabled={isGenerating && generatingSkill === selectedSkill}>
                    {isGenerating && generatingSkill === selectedSkill ? 'Generating...' : `Generate ${difficulty} Question`}
                  </button>
                  <p>{calculateSpecificDifficultyPercentage(selectedSkill, difficulty as 'Easy' | 'Medium' | 'Hard')}% correct</p>
              </div>
            ))}
          </div>

          {questionText && renderLatexOrText(questionText)} {/*questionText before "&&" prevents rendering before a question exists */}
              
          {/* Conditionally render the answer options or the free-response form */}
          {questionText && choices.length === 0 ? (
            <form onSubmit={handleFreeResponseSubmit} className="free-response-form">
              <input
                type="text"
                className={`form-input ${interactionStage === 'answered' && feedback === 'Correct!' ? 'correct-border' 
                  : interactionStage === 'answered' && feedback === 'Incorrect, please try again!' ? 'incorrect-border'
                  : ''}`}
                placeholder="Enter your answer..."
                value={freeResponseInput}
                onChange={(e) => setFreeResponseInput(e.target.value)} // Update state on input change
                disabled={isAnswerSubmitted && feedback === 'Correct!'}
              />
              {questionText ? (<button type="submit" onClick={handleFreeResponseSubmit} className="cta-button" disabled={isAnswerSubmitted && feedback === 'Correct!'}>
              Submit Answer
              </button>) : ''}

              {feedback && <p className={`feedback-message ${freeResponseInput.trim() === correctAnswer ? 'correct-feedback' : 'incorrect-feedback'}`}
              >{feedback}</p>}
            </form>
            ) : (
            <div className="choices-grid">
              {choices.map((choice, index) => {
                const choiceLetter = String.fromCharCode(65 + index);
                const isSelected = selectedAnswer === choiceLetter;
                const isIncorrect = incorrectAnswers.includes(choiceLetter);
                const isCorrect = selectedAnswer === correctAnswer;
                // Determine the class to apply based on the state
                let buttonClass = ''; //no additional class/color changes to buttons.
                if (isIncorrect) {
                  buttonClass = 'incorrect-answer'; // Red for previously submitted incorrect answers
                } else if (isAnswerSubmitted && isSelected) {
                  buttonClass = isCorrect ? 'correct-answer' : 'incorrect-answer'; // Highlight the current submitted answer
                } else if (isSelected) {
                  buttonClass = 'selected'; // Blue for currently selected answer before submission
                }
                
                return (
                  <button
                    key={index}  
                    value={choiceLetter} className={`answer-choice-btn ${buttonClass}`}
                    onClick={() => {
                      setSelectedAnswer(choiceLetter);
                      setIsAnswerSubmitted(false); // Reset the submitted state to allow new submissions
                    }}
                    disabled={isAnswerSubmitted && isCorrect} // Disable if incorrect
                  >
                    <span className="choice-text">{renderLatexOrText(choice)}</span> 
                  </button>
                ); //This used to be in the span above: className="choice-text"
              })}

              {questionText ? (<button onClick={handleSubmitAnswer} className="cta-button" disabled={isAnswerSubmitted && selectedAnswer === correctAnswer}>
              Submit Answer
              </button>) : ''}
            </div> 
          )}             
        
          {feedback && (
            <div className="explanation-container">
              <div className="explanation">
                <h4>Explanation:</h4>
                {renderLatexOrText(explanationText)}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SkillButtonsAndQuestionGenerator;

/* {/*</div> }

          {/*<div className="chat-component-right-section"> 
            <ChatComponent />
          </div> }


Previous radial buttons with circles instead of buttons. This was after "Choices" but before "the handleSubmitAnswer" button:
<ul>
            {choices.map((choice, index) => {
              const choiceLetter = String.fromCharCode(65 + index);
              return (
                <li key={index}>
                  <label>
                    <input
                      type="radio"
                      name="answer"
                      value={choiceLetter}
                      onChange={(e) => setSelectedAnswer(e.target.value)}
                    />
                    {choice}
                  </label>
                </li>
              );
            })}
          </ul>

*/

/* This is all embedded in the direct text display because it presents the # attempted to the user. 
  Remove me if after 9/24 and this is working properly.;
  Function to calculate overall skill percentage correct from user data 
  const calculateOverallPercentage = (skill: string) => {
    if (!user) return 0;
    const skillKey = skillNameToKey(skill);
    const skillData = user.skills[skillKey];
    const attempted = skillData?.overallQuestionsAttempted || 0;
    const correct = skillData?.overallQuestionsCorrect || 0;
    const percentage = attempted === 0 ? 0 : Math.round((correct / attempted) * 100);
    return {attempted, correct, percentage};
  };
  */



  /* From line 259, removed on 10/7 after it was already commented out:
  
  Show progress details on the Skill Button if selected - old feature, removed now.
              {isSelected && (
                <div className="progress-grid">
                  {['Easy', 'Medium', 'Hard'].map((difficulty) => (
                    <div key={difficulty} className="progress-row">
                      <div className="difficulty-name">{difficulty}</div>
                      <div className="progress-percentage">
                        {calculateOverallPercentage(skill, difficulty as 'Easy' | 'Medium' | 'Hard')}%
                      </div>
                      <div className="generate-btn">
                        <button onClick={() => {
                          if (selectedSkill !== ""){generateQuestion(skill, difficulty as 'Easy' | 'Medium' | 'Hard');}
                        }} className="cta-button"
                        disabled={isGenerating}
                        >{isGenerating? 'Generating question...' : 'Generate Question'}</button>
                        
                      </div>
                    </div>
                  ))}
                </div>
              )}



/* This is from line 105, after all the SetQuestion, etc. As of 10/8 this is removed because I'll directly load questions from JSON.
SET PROMPT HERE
    let prompt = '';
    if (skill === 'Referencing Data' || skill === 'Interpreting Graphs'){
      prompt = `Generate a ${difficulty}-difficulty Digital SAT-style question that has exactly one correct answer and three plausible but incorrect answers. This should test the student's knowledge of ${skill}. The math expressions **must** be enclosed within $...$ for inline math and \\[...\\] for block math. Before providing the final output, review the question and ensure that these are followed; if they aren't, modify the question or generate a new question that does follow these: 
      1) Super important: All tables must be formatted in Markdown, like this: 
| Color           | Refractive Index |
|-----------------|------------------|
| Blue            | 1.53             |
| Yellow          | 1.51             |
| Red             | 1.50             |
| Infrared (IR)   | 1.49             |
      2) Only one answer choice is correct. 
      3) The incorrect answer choices are still plausible and relevant to the question. 
      4) The explanation clearly justifies why the correct answer is right and why the other choices are incorrect.
      5) Ensure that the content, context, and topics of the question are unique and not directly derived or copied from the provided examples. 
      Introduce new themes, subject matter, and scenarios that still align with SAT standards. Those might include recent scientific and technological innovations, lesser-known historical events, excerpts from historical world literature, or creative fiction.
      6) Any math expressions or variables **must** have "$" before and after them (i.e. $x$ or $y = mx + b$) and should be in LaTeX format.
      7) The output **must** strictly follow this format: Question: [INSERT QUESTION TEXT] Choices: A) [CHOICE A TEXT] B) [CHOICE B TEXT] C) [CHOICE C TEXT] D) [CHOICE D TEXT] Answer: [CORRECT ANSWER LETTER] Explanation: [EXPLANATION TEXT]`;
    } 
    else if (selectedSubject === 'Math') {
      prompt = `Generate a ${difficulty}-difficulty Math Digital SAT-style question that has exactly one correct answer and three plausible but incorrect answers. This should test the student's knowledge of ${skill}. The math expressions **must** be enclosed within $...$ for inline math and \\[...\\] for block math. Before providing the final output, review the question and ensure that these are followed; if they aren't, modify the question or generate a new question that does follow these:
      1) All elements referenced by the question (e.g., underlined phrases, tables, equations) are included and correctly formatted. 
      2) Only one answer choice is correct. 
      3) The incorrect answer choices are still plausible and relevant to the question. 
      4) The explanation clearly justifies why the correct answer is right and why the other choices are incorrect.
      5) The output **must** strictly follow this format: Question: [INSERT QUESTION TEXT] Choices: A) [CHOICE A TEXT] B) [CHOICE B TEXT] C) [CHOICE C TEXT] D) [CHOICE D TEXT] Answer: [CORRECT ANSWER LETTER] Explanation: [EXPLANATION TEXT]`;

       } else {
        prompt = `Generate a ${difficulty}-difficulty Digital SAT-style question that has exactly one answer choice that correctly answers the question and three plausible but incorrect answer choices. This question should test the student's knowledge of ${skill}. Before providing the final output, review the question and ensure that these are followed; if they aren't, modify the question or generate a new question that does follow these:
        1) Only one answer choice is correct. 
        2) The incorrect answer choices are still plausible and relevant to the question. 
        3) The explanation clearly justifies why the correct answer is right and why the other choices are incorrect.
        4) Ensure that the content, context, and topics of the question are unique and not directly derived or copied from the provided examples. 
        Introduce new themes, subject matter, and scenarios that still align with SAT standards. Those might include recent scientific and technological innovations, lesser-known historical events, excerpts from historical world literature, or creative fiction.
        5) The output **must** strictly follow this format: Question: [INSERT QUESTION TEXT] Choices: A) [CHOICE A TEXT] B) [CHOICE B TEXT] C) [CHOICE C TEXT] D) [CHOICE D TEXT] Answer: [CORRECT ANSWER LETTER] Explanation: [EXPLANATION TEXT]`;
      
      //  prompt = `Generate a Digital SAT-style ${difficulty} difficulty Reading and Writing question on ${skill}. Ensure the question tests a student's creative thinking and critical skills. Generate unique content (including questions, answer choices, and answers), context, and topics, especially those that relate to current/recent global events, to ensure question integrity. Ensure exactly one answer choice is correct, while the other three are incorrect. The correct answer choice should come after "Answer:" and should be followed by concise explanation of the answer, labeled "Explanation: ". Format the answer choices using A), B), C), and D) to label the choices. Format the problem as follows: Example Context: [CONTEXT] Question: [INSERT QUESTION] Choices: A) [CHOICE A] B) [CHOICE B] C) [CHOICE C] D) [CHOICE D] Answer: [ANSWER CHOICE] Explanation: [EXPLANATION]`;
    }

    try {
      const response = await fetch('/api/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: prompt }),
      });

      if (!response.ok) {
        const errorData = await response.text();
        console.error('Error response from server:', errorData);
        throw new Error('Failed response from server.');
      }

      //Receives and parses json API response from OpenAI's API
      const data = await response.json();
      const content = data.content;

      console.log(`Content before parsing: ${content}`);
      //Parses the string by "Question: ", "Choices: ", "Answer: ", and "Explanation: ", as shown in the function above.
      parseQuestionContent(content);
      console.log(`Content after parsing: 
        Question: ${questionText}
        Choices: ${choices}
        Answer: ${correctAnswer}
        Explanation: ${explanationText}`);
        */


        // Construct the file path based on the selected subject and skill
      //const formattedSkill = skill.toLowerCase().replace(/ /g, '-');
      //const formattedSubject = selectedSubject.toLowerCase().replace(/ /g, '-');

      /* PRevious way of loading separate files. Now I"l just load the one test56.json
      Assuming the JSON files are stored in a 'satquestions' folder in the public directory
      const fileName = `/satquestions/${formattedSubject}/${formattedSkill}.json`;
      console.log(`Loading questions from: ${fileName}`);
      */


      /*
  const parseQuestionContent = useCallback(
    (content: string) => {
      // Implement parsing logic here
      const questionMatch = content.match(/Question:\s*(.*?)\nChoices:/s);
      const choicesMatch = content.match(/Choices:\s*(.*?)\nAnswer:/s);
      const answerMatch = content.match(/Answer:\s*([A-D])/);
      const explanationMatch = content.match(/Explanation:\s*(.*)/s);

      const questionText = questionMatch ? questionMatch[1].trim() : '';
      const choicesText = choicesMatch ? choicesMatch[1].trim() : '';
      const correctAnswer = answerMatch ? answerMatch[1].trim() : '';
      const explanationText = explanationMatch ? explanationMatch[1].trim() : '';

      // Split choices using A), B), C), D) as markers
      const choicesArray = choicesText.split(/(?:A\)|B\)|C\)|D\))/).map((choice) => choice.trim()).filter(Boolean);
      //Old version which used line breaks to split rather than A, B, C, and D:
      //const choicesArray = choicesText.split(/\n/).map((choice) => choice.trim());

      setQuestionText(questionText);
      setChoices(choicesArray);
      setCorrectAnswer(correctAnswer);
      setExplanationText(explanationText);
    },
    [setQuestionText, setChoices, setCorrectAnswer, setExplanationText]
  );   */



  /* Set the color for each button based on whether it's correct or incorrect
  This was from the "handleSubmitAnswer" function:
    const choiceButtons = document.querySelectorAll('.answer-choice-btn');
    choiceButtons.forEach((button) => {
      const btn = button as HTMLButtonElement;
      if (btn.value === correctAnswer) {
        btn.classList.add('correct-answer');
      } else if (btn.value === selectedAnswer) {
        btn.classList.add('incorrect-answer');
      }
    }); */



    /*Cut at 10:15 pm on 11/11/2024 when this content was transfered to ContextProvider:
      const fileName = '/satquestions/all-sat-tests-final.json'
      console.log(`Loaded the file: ${fileName}`);
      // Fetch the JSON file
      const response = await fetch(fileName);
      console.log(`Fetched ${response.url}, status: ${response.status}`);

      if (!response.ok) {
        throw new Error(`Failed to load questions for ${skill}. Server responded with status ${response.status}.`);
      }

      //const responseText = await response.text();   //console.log('Response text: ', responseText)
      const data = await response.json();  //console.log('Got the data! Here it is: ', data);

      //For more filters, use "filteredSKillQuestions", which is an ARRAY of questions, not an OBJECT with 'questions' at the first level and array of questions underneath
      //So just directly filter the "filteredSkillQuestions", not "filteredSkillQuestions.questions" */
      //const filteredSkillQuestions = data.questions.filter((q: any) => q.skill === skill); 
