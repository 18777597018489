import React, { useState, useContext } from 'react';
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';

type LoginProps = {
  onLoginSuccess: () => void;
  toggleDropdown: () => void;
  dropdownRef: React.RefObject<HTMLDivElement>;
  showLogin: boolean;
};

const Login: React.FC<LoginProps> = ({ onLoginSuccess, toggleDropdown, dropdownRef, showLogin } ) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null); // Add state to hold error messages
  const [success, setSuccess] = useState<string | null>(null); // State to hold success messages
  const navigate = useNavigate(); //initializes navigate
  const { user, initialLoading } = useContext(UserContext); //probably don't need initialLoading...or even loading?

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Set persistence to maintain the session even after the user closes the browser
      await setPersistence(auth, browserLocalPersistence);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      //const user = userCredential.user; //logs into Firestore to get database values of user data (progres on SAT, etc.)
      //Removed const user = userCredential.user because UserContext is where the user's data is loaded, and then shared via UserProvider
      //Login.tsx is just for authenticating email and password
      console.log('User logged in:', userCredential.user);

      setError(null); // Clear any previous errors if login is successful
      setSuccess('Login successful! Redirecting...'); // Show success message
      onLoginSuccess(); //Close dropdown after successful login

      //Added on 9/16/2024:
      //Load the user's data from Firestore
      const userDocRef = doc(db, 'users', userCredential.user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        console.log('User data:', userData);
        // You can now use userData in your app (e.g., set it in context or state). No need to manually update it since it has its own Listeners.
      } else {
        console.log('No such document!');
      }

      // Redirect the user to the homepage or a dashboard after successful login
      setTimeout(() => {
        navigate('/sat'); // Redirect after showing the success message
      }, 1500); // Wait 2 seconds before redirecting
    
      // Or immediately redirect the user to the homepage or a dashboard after successful login with this:
      //navigate('/sat'); // Or wherever you want to redirect the user after signing in

    } catch (error: any) {
      console.error('Error logging in:', error);

      // Set appropriate error messages based on the error type
      if (error.code === 'auth/wrong-password') {
        setError('Incorrect password. Please try again.');
      } else if (error.code === 'auth/user-not-found') {
        setError('No user found with this email. Please check your email address or register.');
      } else if (error.code === 'auth/invalid-email') {
        setError('The email address is formatted incorrectly. Please try again.');
      } else {
        setError('Failed to log in. Please try again later.');
      }

      setSuccess(null); // Clear the success message if there was an error
    }
  };

  /* Function to truncate the name if longer than 12 characters
  const getDisplayName = (name: string) => {
    return name.length > 12 ? `${name.slice(0, 9)}...` : name;
  };*/

  //If you want to reference the user's name on the Login button, you can do: {getDisplayName(user.firstName)}
  return (
    <div>
      {initialLoading ? (
        <button className="login-button">Loading...</button>
      ) : user ? (
        <button className="login-button" onClick={toggleDropdown}>Logged in!</button>
      ) : (
        <button className="login-button" onClick={toggleDropdown}>Login</button>
      )}
    
    
      {/* Conditionally Render the Dropdown */}
      <div ref={dropdownRef} className={`login-dropdown ${showLogin ? 'show' : ''}`}>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            className="form-input"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            className="form-input"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <br></br>
          <button className="cta-button" type="submit">Login</button>

          {/* Display success or error message if there is one */}
          {success && <p className="login-success-message">{success}</p>}
          {error && <p className="error-message">{error}</p>}

          <p>Don't have an account? <Link to="/">Register on the home page!</Link></p>
        </form>
      </div>
    </div>
  );
};

export default Login;
