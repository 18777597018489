import React, {useState, useContext, useEffect } from 'react';
import { PromptContext } from './PromptContext'; // Ensure the path is correct
import { UserContext } from './UserContext';

type ChatComponentProps = {
    //props
}

type Message = {
    role: 'system' | 'user'| 'assistant';
    content: string;
}

const ChatComponent: React.FC<ChatComponentProps> = () => {
    const [userInput, setUserInput] = useState<string>('');
    const [conversation, setConversation] = useState<Message[]>([]); //New on 11/15: includes system prompt in addition to user and assistant
    //const [conversation, setConversation] = useState<{role: 'user' | 'bot', content: string}[]>([]); //previous version
    const [selectedModel, setSelectedModel] = useState<string>('gpt-4'); // New on 11/15: State for model selection

    const [error, setError] = useState<string>('');
    const [isGeneratingResponse, setIsGeneratingResposnse] = useState<boolean>(false);
    //const [interactionStage, setInteractionStage] = useState<'notStarted' | 'questionGenerated' | 'answered'>('notStarted');

    const { user } = useContext(UserContext);
    const context = useContext(PromptContext); // Access the generated SAT question and clearChat method from context
    //const generatedSATQuestion = context?.generatedSATQuestion || "";
    const generatedQuestion = context?.questionText || "";
    const generatedQuestionChoices = context?.choices || "";
    const generatedQuestionAnswer = context?.correctAnswer || "";
    const generatedQuestionExplanation = context?.explanationText || "";


    // New on 11/15: System prompt to guide the AI's behavior. Customize the About Me section with information from UserContext soon.
    const systemPrompt = `You are Atonifex, an AI tutor specialized in helping high school students learn relevant core knowledge and test-taking strategies to solve Digital SAT problems. 
    Your responses should be easily understandable, short (50-120 words), and engaging for high school students. Ask the user questions to help develop their knowledge along the way. 
    Never explicitly give the answer away; instead, lead the student's thinking with questions. Don't ask more than 2 questions back to back unless the student's understanding requires further support or scaffolding.
    
    About Me, the Student User: ${user ? `My name is ${user.firstName}. ` : ''}I am a high-school student, and I enjoy encouraging and insightful feedback. 
    
    Formatting: For all mathematical expressions, use LaTeX formatting with "$" before and after (ex: $y = \\frac{1}{2}x + b$).
    
    ${generatedQuestion ? `Current SAT Question: ${generatedQuestion}. Answer Choices: ${generatedQuestionChoices}. 
                Correct Answer: ${generatedQuestionAnswer}. Official SAT Explanation: ${generatedQuestionExplanation}.`: ''}`; 

    console.log(`systemPrompt is: ${systemPrompt}`);
                //Use HTML to represent tables if they significantly improve comprehension, but generally it's better to reference the values in the table within generatedQuestion.

    //Clears the Chat History by detecting chatCleared = true in PromptContext & conversation length > 0
    useEffect(() => {
        try{
            if (context?.chatCleared && conversation.length > 0) {
                setConversation([]); // Clear the conversation state
                //console.log("Cleared Conversation in ChatComponent's useEffect!!");
            }
        }
        catch (error){
            console.log ("Error in the useEffect of ChatComponent clearing Conversation: ", error)
        }
        
    }, [context?.chatCleared, conversation.length]); // Trigger effect whenever chatCleared flag changes

    // Array of optional prompts for quick input based on interactionStage. Added on 11/12
    const quickPrompts = context?.interactionStage === 'notStarted'
    ? ["Which question types should I practice first?",
        //"What are common study mistakes?",
        "Can you tell me a joke and then inspire me to work hard?"]
    : context?.interactionStage === 'questionGenerated'
    ? ["Can you give me a hint to start?",
        //"What's the key knowledge I should know?",
        "What's a test-taking strategy I could use here?"]
    : ["I don't get this. Can you explain how to solve this?",
        //"What should I do differently next time?",
        "Can you explain the key concepts?"];

    // NEW on 11/12 at 12:14 am: Event handler for quick prompt clicks
    const handleQuickPromptClick = (quickPrompt: string) => {
        console.log("handlingQuickPromptClick");
        setUserInput(quickPrompt); // Set the input field value to the selected prompt
        console.log(`setUserInput to ${userInput}`);
        // Simulate a form submission with the quick prompt after updating the state
        setTimeout(() => {
            fetchChatResponse(quickPrompt); // Ensure prompt is passed correctly
        }, 0);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInput(e.target.value);
    }

    // New on 11/15: Function to truncate conversation history for token management
    const getTruncatedConversation = (fullConversation: Message[], maxMessages: number = 10): Message[] => {
        // Filter out the system message
        const nonSystemMessages = fullConversation.filter(msg => msg.role !== 'system');
        const truncatedMessages = nonSystemMessages.slice(-maxMessages); //returns last 10 messages
        return [ { role: 'system', content: systemPrompt }, ...truncatedMessages ];
    };

    const fetchChatResponse = async(input: string = userInput): Promise<void> => { //the ": Promise<void>" declares that there is no return or output.
        console.log("Beginning fetchChatResponse");
        setIsGeneratingResposnse(true);
        if (!input.trim()) return; //This is to prevent the user from sending empty messages.

        const prompt = input //probably unnecessary now; FUTURE WORK: just change the "prompts" below to "input", unless there will be more dynamic changes here.

        // New on 11/15: Prepare the new user message
        const newUserMessage: Message = { role: 'user', content: prompt };
        console.log('prompt to be sent is: ', prompt);

        // New on 11/15: Update the conversation state with the new user message
        const updatedConversation = [...conversation, newUserMessage];

        // New on 11/15: Truncate the conversation for API call
        const messagesForAPI = getTruncatedConversation(updatedConversation);
        
        try{
            const res = await fetch('/api/chat', { //This is the fetch call to the backend. It's the same as the "response" in the back end.
                //https://my-react-openai-app.vercel.app is the production url, but the fetch should be the relative '/api/chat' for preview & production.
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    messages: messagesForAPI, //NEW on 11/15: sends conversation history with roles rather than 1 concatenated string, reducing AI's confusion on who sent which
                    model: selectedModel //will be useful later for specifying when to use regular vs. gpt-40-mini for summarization
                })
            });

            if (!res.ok){
                const errorData = await res.json();
                setError(errorData.error || 'Something went wrong. Look in the if(!res.ok) part of ChatComponent.js');
                setIsGeneratingResposnse(false);
                return;
            }

            const data = await res.json();
            const assistantMessage: Message = { role: 'assistant', content: data.content }; //AI's response. Doesn't control for the data type without specifying ".content"
            // New on 11/15: Update the conversation state with the assistant's response
            setConversation([...updatedConversation, assistantMessage]);
            
            console.log('Conversation history after AI fetch response: ', conversation);
            
            setUserInput(''); //Clear input field after sending
            setIsGeneratingResposnse(false);

        } catch (error) {
            console.error('Error in the front-end fetchChatResponse catch: ', error)
            setError('Failed to fetch data from the server.');
            setUserInput(''); //Clear input field after sending
            setIsGeneratingResposnse(false);
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // Prevents default form submission behavior
        fetchChatResponse(); // Calls the function to send the message
    };
    

    return (
        <div className="ChatComponent">
            {user && user.firstName ? (<h2>Hey {user.firstName}, it's Ivan, your AI tutor!</h2>) : (<h2>Hey, it's Ivan, your AI tutor!</h2>)}
            <p>Have you ever felt like you missed something in class but didn't want to ask? Have you forgotten anything from previous years' classes?</p>
            <p>This is your chance to explore your curiosity! As you practice, type questions below or select the quick prompts to develop your skills to succeed!</p>
            
            {/* NEW: Render quick prompt bubbles ....removed: disabled={isGeneratingResponse}*/}
            <div className="quick-prompts">
                {quickPrompts.map((quickPrompt, index) => (
                    <button 
                        key={index} 
                        className="quick-prompt-bubble" 
                        onClick={() => handleQuickPromptClick(quickPrompt)}
                        
                    >
                        {quickPrompt}
                    </button>
                ))}
            </div>

            <form className = 'chat-form-input' onSubmit={handleSubmit}>
                <input 
                    type="text"
                    value={userInput}
                    onChange={handleInputChange}
                    placeholder="Type your questions here...ex: What should I do when...?"
                    className="chat-input"
                />      
                <button type="submit" className="cta-button">{isGeneratingResponse ? 'Generating...' : 'Send'}</button>
            </form>  
            
            <div className={`chat-container ${conversation.length > 0 ? 'chat-container-active' : ''}`}>
                {conversation.length > 0 ? 
                    (conversation.map((msg, index) => (
                    <p key={index} className={`message ${msg.role === 'user' ? 'user-message' : 'ai-message'}`}>
                        {msg.role === 'user' ? "Your input prompt: " : "AI Tutor's response: "}
                        {msg.content ? context?.renderLatexOrText(msg.content) : "No content available"} 
                    </p>
                    )) 
                )  :  (
                     <p className="no-conversation"></p>
                    )
                }
            </div>
            
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};
export default ChatComponent;

// ***Before 11/15 way of prompting without system prompts and by concatenating all conversation history into the userInput:
//Combine user input with the context from previous conversations
        //***********************************************************************************8 */
        //TO DO ON 11/14 or 15: Add in if it's the user or the ai associated with each message, have
        //If prevMessages gets longer than like 5, consider having a gpt message in the middle summarize the context of the first ones 
        /*   so only the most recent 4 messages are displayed 
        const prevMessages = conversation.map((msg) => msg.content).join(' ');
        const skillsRequiringLatex = ['Absolute Value', 'Algebra', 'Circles', 'Exponential Equations', 'Exponential Word Problems', 'Geometry', 'Interpreting Graphs', 'Linear Equations', 'Linear Word Problems', 'Inequality Word Problems', 'Percent', 'Polynomial Expressions', 'Probability', 'Quadratic Equations', 'Quadratic Word Problems', 'Statistics', 'Systems of Equations', 'Trigonometry', 'Unit Conversions']
        const skillsRequiringHTMLTables = ['Interpreting Graphs', 'Referencing Data']
        const containsMath = skillsRequiringLatex.includes(context?.selectedSkill || '');
        const requiresHTMLTables = skillsRequiringHTMLTables.includes(context?.selectedSkill || '');

        let prompt = "";

        if (prevMessages?.length === 0){
            prompt = `${input}`; 
            if (containsMath === true) {
                prompt = prompt + ' EXTREMELY IMPORTANT NOTE: All math expressions must use LaTeX formatting, with a "$" before and after. Example: $y = \\frac{2^{x+2}}{\\sqrt{2}}$. Before finishing your output, review all math expressions and add a "$" before and after (like $...$, not [...]) if they did not have "$" previously; if you do not, I cannot read your math explanations and will be extremely disappointed.';}
            if (requiresHTMLTables === true) {
                prompt = prompt + ' If you need to reference the table in any future chat, make sure to use HTML formatting. Example: <table border=\\"1\\"><tr><th>Group</th><th>Number of cans</th></tr><tr><td>1</td><td>30</td></tr><tr><td>2</td><td>63</td></tr><tr><td>3</td><td>38</td></tr><tr><td>4</td><td>50</td></tr><tr><td>5</td><td>47</td></tr><tr><td>6</td><td>40</td></tr><tr><td>7</td><td>54</td></tr><tr><td>8</td><td>60</td></tr><tr><td>9</td><td>18</td></tr><tr><td>10</td><td>20</td></tr></table>';}
            if (generatedQuestion?.length > 0) {
                prompt = prompt + `SAT Question: ${generatedQuestion}. Answer Choices: ${generatedQuestionChoices}. 
                    Correct Answer: ${generatedQuestionAnswer}. Official SAT Explanation: ${generatedQuestionExplanation}.`;}
        } else if (prevMessages?.length > 0) { //assuming there was a previous message. This should later become "else" and the bottom one shoudl be removed
            prompt = `${input}. Also, here is context from our previous messages. Always remember the SAT question, its answer choices, its explanation, and always follow all formatting instructions, but if you see a previous user question that was already answered, you can probably ignore it. 
            Before you complete your response, double check that the formatting is followed exactly: ${prevMessages}`
        } else {
            prompt = "Tell me that I'm a visionary and should push onward on despite failures and challenges."
        }
        //The "?" checks if there's a generatedSATQuestion. If so, adds it + choices + answer + explanation.
        if (prevMessages?.length > 0) {
            prompt = prompt + `Also, use context from our previous messages: ${prevMessages}`;}


            ...


            /*Old version before 11/15: 
            setConversation((previousConversation) => [
                ...previousConversation, 
                { role: 'user', content: input}, 
                { role: 'bot', content: data.content }]); //**Doesn't control for the data type without specifying ".content" 
                 
            ...
            //message: prompt //Old version: inludes userInput + context:updatedConversation. The backend will add the role: 'user' to this.
                */
        


 /*Old: Used to choose which quickPrompts are shown to user depending on if they've generated a question  (yes/no)
    useEffect(() => {
        if (generatedQuestion) {
            context?.setInteractionStage('questionGenerated');
        } else {
            context?.setInteractionStage('notStarted'); // Reset when no question is present
        }
    }, [generatedQuestion]); */



/* Old version:
    return (
        <div>
            <input 
                type="text"
                value={userInput}
                onChange={handleInputChange}
                placeholder="Type your message here..."
                />                
            <button onClick={fetchChatResponse}>Send</button>
            
            <div>
                {conversation.map((msg, index) => (
                    <p key={index} style={{ color: msg.role === 'user' ? 'blue' : 'green' }}>
                        {msg.role === 'user' ? "Your input prompt: " : "AI Tutor's response: "}
                        {msg.content}
                    </p>
                ))}
            </div>
            
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};
*/